<template>
	<div>
		<label :for="id" :class="twMerge('text-white text-base font-jakarta', labelClass)" v-if="!!label">{{
			label
		}}</label>
		<div
			:class="[
				twMerge(
					'bg-[#141718] rounded-xl px-5 group focus-within:ring-stone-500/40 focus-within:ring-2 flex items-center gap-3',
					inputClass || ''
				),
				{
					'mt-2': !!label,
				},
			]"
		>
			<slot name="icon" />

			<Input
				class="bg-transparent w-full text-white outline-none h-fit py-5"
				v-bind="$attrs"
				wrapperClass="focus-within:ring-0"
				v-model="inputModel"
				:id="id"
				type="text"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { twMerge } from 'tailwind-merge'

const id = useId()
const inputModel = defineModel()
defineProps({
	label: {
		type: String,
	},
	labelClass: {
		type: String,
	},
	inputClass: {
		type: String,
	},
})
</script>
